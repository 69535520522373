import React from 'react'

interface ExcerptProps {
  content: any
  number?: number
  words?: boolean
  className?: string
  removeTables?: boolean
}

const Excerpt = ({ content = '', number = 350, words = false, className = '', removeTables = false }: ExcerptProps) => {
  if (content) {
    const regex = /(<([^>]+)>)/gi
    const removeScirptTags = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
    const removeTableTags = /<table\b[^<]*(?:(?!<\/table>)<[^<]*)*<\/table>/gi

    const ScriptsCleared = content?.replace(removeScirptTags, '')

    const TablesCleared =  removeTables ? ScriptsCleared.replace(removeTableTags, '') : ScriptsCleared

    const format = TablesCleared?.replace(regex, '')

    const formatSpaces = format?.replace(/(\r\n|\n|\r)/gm, '').trim()
    const cutContent = words ? formatSpaces.split(' ').slice(0, number).join(' ') : formatSpaces.slice(0, number)

    const addDots = formatSpaces.length > cutContent.length ? '...' : ''
    const final = `${cutContent}${addDots}`

    return <p className={className} dangerouslySetInnerHTML={{ __html: final }} />
  } else {
    return <></>
  }
}

export default Excerpt
