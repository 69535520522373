import React from 'react'
import ListElement from '@/components/ListElement'

export interface AlphabetListProps {
  entries: any
}

const AlphabetList = ({ entries }: AlphabetListProps) => {
  const alphabet = entries.nodes.reduce((r: any, e: any) => {
    // get first letter of name of current element
    const group = e.title[0]
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] }
    // if there is push current element to children array for that letter
    else r[group].children.push(e)
    // return accumulator
    return r
  }, {})

  const alphabetArr = Object.values(alphabet)

  return (
    <>
      {alphabetArr?.map((item: any) => {
        if (item?.children?.length > 0) {
          // Split Arr to half
          const split = Math.ceil(item?.children?.length / 2)
          // Left col
          const first = item?.children?.slice(0, split)
          // Right col
          const last = item?.children?.slice(
            -split + Number(item?.children?.length % 2 == 0 && item?.children?.length != 1 ? 0 : 1)
          )
          return (
            <section className="my-10" key={item.group}>
              <h2 className="mb-5">{item?.group}</h2>
              <div className="grid sm:grid-cols-2 sm:gap-10">
                <div className="">
                  {first?.map((link: any, i: number, r: any) => {
                    return (
                      <ListElement key={link?.title} to={link?.url}>
                        {link?.title}
                      </ListElement>
                    )
                  })}
                </div>
                {item?.children?.length > 1 && (
                  <div className="">
                    {last?.map((link: any, i: number, r: any) => {
                      return (
                        <ListElement key={link?.title} to={link?.url}>
                          {link?.title}
                        </ListElement>
                      )
                    })}
                  </div>
                )}
              </div>
            </section>
          )
        }
      })}
    </>
  )
}

export default AlphabetList
