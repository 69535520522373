import React from 'react'
import ButtonLink from '@/components/ButtonLink'
import Moment from 'react-moment'
import Excerpt from '@/components/Excerpt'

export interface RelatedItemProps {
  content: any
  heading: string
  to: string
  date: string
  featuredType: any
}

const RelatedItem = ({ heading, content, to, date, featuredType }: RelatedItemProps) => {
  return (
    <div className="border-t-[20px] border-secondary flex flex-col my-2">
      <div>
        <div className="flex mt-6 text-2xl">
          <p className="mr-2">
            <Moment format="DD.MM.YYYY" date={date} />
          </p>
          {featuredType && (
            <p className={`ml-auto text-right ${featuredType.length > 1 ? 'text-sm' : ''}`}>
              {featuredType.map((term: any, index: number) => {
                return <span key={index}>{term.name} </span>
              })}
            </p>
          )}
        </div>
        <div className="mt-5 mb-4">
          {heading && (
            <h3 className="mb-6">
              <Excerpt content={heading} number={60} />
            </h3>
          )}
          {content && <Excerpt className="font-futuraBook" content={content} number={150} />}
        </div>
      </div>
      {to && (
        <div className="flex mt-auto justify-center lg:mb-10">
          <ButtonLink color="trans" to={to}>
            Lue Lisää
          </ButtonLink>
        </div>
      )}
    </div>
  )
}

export default RelatedItem
