import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import { Gutenberg } from '@/components/Gutenberg'
import RelatedItem from '@/components/RelatedItem'
import AlphabetList from '@/components/AlphabetList'

interface DefaultData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      seo: SeoType
      image: any
      blocks: any
      blocksJSON: any
      bgelement: any
      bgprosent: number
      showposts: boolean
      heroFilter: boolean
    }
    memberCompany: any
    posts: any
    menu?: any
    quickmenu?: any
    wp: any
  }
  pageContext: {
    postlang: string
  }
}

const SingleSections = ({ data }: DefaultData) => {
  // Page data
  const { wp, page, posts, memberCompany } = data
  // Menus
  const { menu, quickmenu } = data

  const heroContent = {
    image: page?.image,
    disableBackButton: true,
    filter: page?.heroFilter,
  }

  return (
    <Layout
      title={page?.title}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      uri={page?.uri}
      seo={page?.seo}
      useBreadCrump={true}
      customBreadCrump={wp?.options?.customBreadCrump}
      bgimage={page?.bgelement?.value}
      bgprosent={page?.bgprosent}
    >
      <div className="mx-auto max-w-[1100px]">
        <Gutenberg blocks={page?.blocks} />
      </div>
      <div className="mx-auto max-w-[1100px]">
        <AlphabetList entries={memberCompany} />
      </div>
      {posts && page?.showposts && (
        <div className="relateditems grid md:grid-cols-3 gap-6 mt-20">
          {posts?.nodes.map((post: any) => {
            return (
              <RelatedItem
                key={post?.id}
                heading={post?.title}
                content={post?.content}
                to={post?.uri}
                date={post?.date}
                featuredType={post?.terms?.nodes}
              />
            )
          })}
        </div>
      )}
    </Layout>
  )
}

export const PageQuery = graphql`
  query SectionById($id: String, $pagetag: [String], $section: String) {
    page: wpGwSection(id: { eq: $id }) {
      id
      content
      title
      uri
      heroFilter: gwShowPostHeaderFilter
      showposts: gwShowPostsOnSection
      ...sectionBlocks
      ...seoSection
      ...featuredHeroImageSection
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    wp {
      options: crbThemeOptions {
        customBreadCrump: gwBreadcrumpsGwSection {
          link
        }
      }
    }
    posts: allWpPost(
      limit: 3
      sort: { order: DESC, fields: date }
      filter: { tags: { nodes: { elemMatch: { name: { in: $pagetag } } } } }
    ) {
      ...allPostsExtended
    }
    memberCompany: allWpGwRemoteItem(
      sort: { fields: title, order: ASC }
      filter: { gwRemoteItemGroup: { regex: $section } }
    ) {
      nodes {
        title
        url: gwRemoteItemRemoteLink
      }
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default SingleSections
